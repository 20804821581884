<template>
  <div
    style="z-index: 3; background-color: white"
    class="scrollable"
    data-testid="map-right-sidebar"
    id="sidebar-container"
  >
    <v-toolbar dark color="primary lighten-1" elevation="0">
      <v-btn tabindex="-1" icon dark @click="closeRightSidebar">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider vertical class="pl-2" />
      <v-toolbar-title class="pl-4">
        <!-- <v-icon class="pl-3 pr-3">{{ pageData.menu_icon }}</v-icon> -->
        Information</v-toolbar-title
      >
      <v-spacer />
      <!-- <v-btn icon dark>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn> -->
    </v-toolbar>
    <v-container class="pb-0">
      <template v-if="pageData && pageData.content">
        <v-row v-for="(block, i) in pageData.content" :key="i">
          <v-col>
            <Streamfield :block="block" />
          </v-col>
        </v-row>
      </template>

      <template
        v-if="
          pageData &&
          pageData.precinct_select &&
          pageData.precinct_select.content
        "
      >
        <v-row v-for="(block, i) in pageData.precinct_select.content" :key="i">
          <v-col>
            <Streamfield :block="block" />
          </v-col>
        </v-row>
      </template>

      <template v-if="groupData && groupData.right_sidebar_content">
        <v-row v-for="(block, i) in groupData.right_sidebar_content" :key="i">
          <v-col>
            <Streamfield :block="block" />
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
// import SidebarPage from "./SidebarPage.vue";
// import AddComment from "./AddComment.vue"
// import ViewComment from "./ViewComment.vue"
import Streamfield from "../../components/blocks/streamfieldBlock.vue";
// import PageShortcutBlock from "../../components/blocks/pageShortcutBlock.vue";

export default {
  name: "Sidebar",
  props: {
    pageData: Object,
    groupData: Object,
  },
  mounted() {},
  methods: {
    closeRightSidebar() {
      this.$store.commit("map/setResetMap", true);
      this.$store.commit("map/setResetJourney", true);
      this.$store.commit("map/setResetLocation", true);
      this.$store.commit("map/setSelectedLocation", null);
      this.$store.commit("map/setRenderLocation", null);
      this.$store.commit("map/setGroupLocation", null);
      this.$store.commit("map/setRightSidebarZoomLevel", null);
      this.$store.commit("map/setPrecinct", null);
      this.$store.commit("map/setBackupPrecinct", null);
      this.$store.commit("map/setHotspotGroupSelectedLocation", null);
      this.$store.commit("map/setJourney", {
        startLocation: null,
        stopLocation: null,
        entrance: null,
        exit: null,
      });
      this.$root.$emit("close-precinct")
    },
  },
  watch: {
    groupData(newVal, oldVal) {
      console.log("clicked myProp changed from", oldVal, "to", newVal);
    },
  },
  components: {
    Streamfield,
  },
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  overflow-x: none;
  /* position: absolute;
    right: 0;
    top: 0;
    left: 0; */
}

.map-legend {
  display: none;
}

.card-text {
  color: rgba(0, 0, 0, 1) !important;
}

@media (max-width: 600px) {
  .map-legend {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .header {
    display: none;
  }
}
</style>
